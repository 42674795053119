const endPoint = {
  login: "authenticate",
  connected: "authenticate/user-connected",
  produits:"produits",
  categories:"categories",
  responsables:"responsables",
  sousresponsables:"sousresponsables",
  clients:"clients",
  stocks:"stocks",
  users:"users",
  livreurs:"livreurs",
  statistiques:"statistiques",
  administrateurs:"administrateurs",
  commandes:"commandes",
  promotions:"promotions",
  boutiques:"boutiques",
  commandeLivreurs:"commande-livreurs"

};

export default endPoint;
